import axios from 'axios'
import store from '../store/index'
import router from '../router/index'
import {
    MessageBox,
} from 'element-ui'
// 请求
const api = axios.create({
    baseURL: "/",
    timeout: 10000
})
// 请求拦截
api.interceptors.request.use((config) => {
    let token = localStorage.getItem('token') || ''
    config.headers.Authorization = 'Bearer ' + token
    config.headers.request_from = 'pc';
    if (config.method == 'get') {
        let params = [];
        for (const key in config.data) {
            if (Object.hasOwnProperty.call(config.data, key)) {
                const element = config.data[key];
                params.push(key + '=' + element)
            }
        }
        params = '?' + params.join('&');
        config.url += params;
    }
    return config
}, err => {
    console.log(err);
})

api.interceptors.response.use(res => {
    // 对响应码的处理
    if (res.status == 200) {
        if(res.data.code == 1002) {
            localStorage.clear();
            router.push("/user/login");
        } else {
            return res.data
        }
    }
}, error => {
    console.log('请求失败', error, error.code=='ECONNABORTED', error.message.indexOf('timeout') != -1);
    if(error.code=='ECONNABORTED' && error.message.indexOf('timeout') != -1) {
       return {
        code: 'timeout',
        msg: '网络连接超时'
       }
    }
    if(error.code == 'ERR_NETWORK') {
        return {
            code: 'timeout',
            msg: '网络连接超时'
           }
    }
})

// 返出
export default api
